// extracted by mini-css-extract-plugin
export var column = "PrivacyPolicy__column__HkJQ8";
export var container = "PrivacyPolicy__container__wUHm1";
export var flex = "PrivacyPolicy__flex___IEsq";
export var flexColumn = "PrivacyPolicy__flexColumn__mkZe6";
export var gap1 = "PrivacyPolicy__gap1__uqIcF";
export var gap2 = "PrivacyPolicy__gap2__GzS2U";
export var gap3 = "PrivacyPolicy__gap3__bSu4p";
export var gap4 = "PrivacyPolicy__gap4__OplFh";
export var gap5 = "PrivacyPolicy__gap5__oTNpK";
export var row = "PrivacyPolicy__row__QT8Ph";